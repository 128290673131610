import { Backdrop, makeStyles } from "@material-ui/core";
import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  circular: {
    [theme.breakpoints.up("md")]: {
      left: "48%",
      top: "65%"
    },
    [theme.breakpoints.up("sm")]: {
      left: "48%",
      top: "54%"
    },
    left: "48%",
    top: "50%",
    // justify: "center",
    // zIndex: "1",
    position: "absolute"
    // // // marginTop: "20%",
    // left: "50%",
    // top: "70%",
    // transform: "translate(-50%, -50%)",
  }
}));
function Loader() {
  const classes = useStyles();
  const { loading = false } = useSelector(state => state.utils);

  return (
    <div>
      <Backdrop open={loading} style={{ zIndex: "10000" }}>
        <CircularProgress
          color="primary"
          className={classes.circular}
        ></CircularProgress>
      </Backdrop>
    </div>
  );
}

export default Loader;
