import React from "react";

import {
  withStyles,
  MuiThemeProvider,
  makeStyles,
} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { theme } from "../../utils/Theme";
import ErrorPopUpImage from "../../assets/img/ErrorPopUpImage.png";
import { useDispatch, useSelector } from "react-redux";
import { removeAlert } from "../../store/actionCreators/utils";
const useStyles = makeStyles(() => ({
  dialogPaper: {
    maxWidth: "50vh",
  },
}));

function AlertModal() {
  const classes = useStyles();
  const { alertMessage = "" } = useSelector((state) => state.utils);
  const dispatch = useDispatch();
  return (
    <MuiThemeProvider theme={theme()}>
      <Dialog
        open={alertMessage}
        fullWidth
        maxWidth="50vw"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogContent>
          <Grid container alignItems="center" direction="row" spacing={2}>
            <Grid align="center" item xs={12} style={{ marginTop: "5%" }}>
              <img
                src={ErrorPopUpImage}
                alt="Error"
                style={{ height: "60%", width: "50%" }}
              ></img>
            </Grid>
            <Grid item align="center" xs={12}>
              <Typography
                variant="subheading"
                alogn="center"
                style={{ fontWeight: "bold" }}
              >
                {alertMessage}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Grid container justify="space-around" alignItems="center">
            <Grid item xs={4} align="center">
              <Button
                onClick={() => {
                  dispatch(removeAlert());
                }}
                color="primary"
                variant="contained"
                size="small"
              >
                Got it
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </MuiThemeProvider>
  );
}

export default AlertModal;
