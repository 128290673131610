import { initialState } from "../initialState";
import { SET_LOADING, SET_ALERT } from "../actions";

export default function utils(state = initialState.utils, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADING:
      return { ...state, ...payload };
    case SET_ALERT:
      return { ...state, ...payload };
    default:
      return state;
  }
}
