import "./App.css";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  showAlert,
  startLoading,
  stopLoading,
} from "./store/actionCreators/utils";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { SnackbarProvider, useSnackbar } from "notistack";

import { theme } from "./utils/Theme";

import Routes from "./Routes";

import axios from "axios";
import Loader from "./components/Utils/Loader";
import AlertModal from "./components/Utils/AlertModal";
import { KITCHEN_FOODCOURTS, LOCATION, LOCATION_AUTH } from "./utils/Constants";
import { useHistory } from "react-router-dom";

export const SchoolContext = React.createContext();

function App() {
  const options = { day: "numeric", month: "long", year: "numeric" };
  const [id, setId] = useState("");
  const [schools, setSchool] = useState();
  const [roles, setRoles] = useState(["Teacher", "Student", "Other"]);
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [restaurants, setrestaurants] = useState([]);
  const [foodcourts, setfoodcourts] = useState([]);
  const [email, setEmail] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [login, setLogin] = useState(false);
  const history = useHistory();

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const dispatch = useDispatch();

  const compare = (a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  };
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // console.log("error", error);
      // console.log("error.status", error.response.status);
      if (error.response.status > 400) {
        setTimeout(() => {
          history.replace("/login");
        }, 300);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    }
  );
  // const base_url = "https://nzdemo.thesmartq.com";.

  const getInitialData = () => {
    dispatch(startLoading());
    const params = {
      location: LOCATION,
      get_foodcourtlist: true,
      get_restaurantlist: true,
    };
    axios
      .get(LOCATION_AUTH, {
        params,
      })
      .then((res) => {
        if (res.data) {
          let data = res.data;
          // console.log(data);
          // console.log(data.foodcourts);

          // let location = Object.keys(res.data.foodcourts)[0];
          // localStorage.setItem("location", location);
          let schoolList = [];
          let restaurantidlist = [];
          let foodcourtlist = [];
          // console.log("location is", window.location.hostname);

          Object.keys(data.foodcourts).map((school) => {
            foodcourts[school] = data.foodcourts[school];
            // console.log("***attention", data.foodcourts[school]);
            if (!KITCHEN_FOODCOURTS.includes(school)) {
              schoolList.push({
                label: data.foodcourts[school].name,
                value: school,
              });
            }
          });

          setrestaurants(data.restaurants);
          // console.log(restaurants);
          setfoodcourts(foodcourts);
          // console.log("Foodcourt data imp", foodcourts);
          // const sortedSchool = [...schoolList].sort();
          // console.log("sorted list is", sortedSchool);

          schoolList.sort(compare);
          setSchool(schoolList);

          dispatch(stopLoading());
        }
      })
      .catch((error) => {
        const { response = {} } = error;
        const { data = {} } = response;
        dispatch(showAlert({ alertMessage: data.message }));
        dispatch(stopLoading());
      });
  };

  const handleInitialRoute = () => {
    const autoInfo = localStorage.getItem("auth_info");
    if (!autoInfo) {
      history.push("/login");
    }
  };

  useEffect(() => {
    getInitialData();
    handleInitialRoute();
  }, []);

  return (
    <MuiThemeProvider theme={theme()}>
      <SnackbarProvider maxSnack={1} style={{ backgroundColor: "#6663F5" }}>
        <SchoolContext.Provider
          value={{
            id: id,
            setId,
            schools: schools,
            setSchool: setSchool,
            roles: roles,
            setRoles: setRoles,
            selectedRole: selectedRole,
            setSelectedRole: setSelectedRole,
            selectedSchool: selectedSchool,
            setSelectedSchool: setSelectedSchool,

            email: email,
            setEmail: setEmail,
            selectedDate: selectedDate,
            setSelectedDate,
            // menu,
            // selectedMenu,
            handleDateChange,
            login,
            setLogin,
            restaurants,
            foodcourts,
          }}
        >
          <Routes />
        </SchoolContext.Provider>
      </SnackbarProvider>
      <AlertModal />

      <Loader />
    </MuiThemeProvider>
  );
}

export default App;
