import { createStore } from "redux";
import rootReducer from "./rootReducer";
import { initialState } from "./initialState";
import { devToolsEnhancer } from "redux-devtools-extension";

let store;
if (process.env.NODE_ENV !== "production") {
  store = createStore(rootReducer, initialState, devToolsEnhancer());
} else {
  store = createStore(rootReducer, initialState);
}

export { store };
