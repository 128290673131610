import utils from "./reducers/utils";
import { combineReducers } from "redux";
import { initialState } from "./initialState";

const appReducer = combineReducers({
  utils
});

const rootReducer = (state, action) => {
  if (action.type === "PURGE_EVERYTHING") {
    state = initialState;
  }
  return appReducer(state, action);
};

export default rootReducer;
