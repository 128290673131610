import { createMuiTheme } from "@material-ui/core/styles";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// colors should change based on the app's theme
// const PRIMARY_COLOR = "#0000FF";
const PRIMARY_COLOR = "#6663F5";

// const SECONDARY_COLOR = "rgb(181, 101, 29)";
// const SECONDARY_COLOR = "#f37021";
const SECONDARY_COLOR = "#0000FF";
const ERROR_COLOR = "#ff3333";

export const theme = () => {
  return createMuiTheme({
    palette: {
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
      error: {
        main: ERROR_COLOR,
      },
    },

    typography: {
      fontFamily: "'Poppins', 'sans-serif'",
    },
    overrides: {
      MuiTypography: {
        h2: {
          fontFamily: "'Poppins', 'sans-serif'",
          color: "#424143",
          marginTop: "5%",
          borderRadius: "5px",
        },
        h6: {
          fontWeight: "bold",
          fontSize: "100%",
        },
        // root: {
        //   width: "90%",
        // },
        // root: {
        //   marginLeft: "5%",
        // },
      },
      MuiButton: {
        root: {
          borderRadius: "5px",
          fontFamily: "'Poppins', 'sans-serif'",
          // width: "95%",
          width: "95%",
          textTransform: "none",
        },

        containedPrimary: {
          color: "white",
          backgroundColor: "#6663F5",
        },
        // label: {
        //   color: "white",
        // },
      },

      MuiSelect: {
        select: {
          width: "100%",
          // border: "0",
        },
      },
      MuiPaper: {
        root: {
          fontFamily: "'Poppins', 'sans-serif'",
        },
      },
      // MuiFormLabel: {
      //   root: {
      //     alignItems: "centre",
      //     fontSize: "120%",
      //     marginLeft: "-3%",
      //     paddingTop: "3%",
      //     // marginTop: "4%",
      //     fontFamily: "'Poppins', 'sans-serif'",
      //   },
      // },
      MuiTextField: {
        root: {
          width: "100%",
          // border: "0",
        },
      },
      MuiCard: {
        root: {
          width: "90%",
          // height: "40%",
          // marginTop: "2%",
          // marginBottom: "2%",
          // paddingBottom: "0%",
        },
      },
      MuiFormControl: {
        marginNormal: {
          marginTop: "0px",
          marginBottom: "0px",
        },
      },

      MuiInputBase: {
        root: {
          fontSize: "100%",
        },
        // input: {
        //   fontWeight: "bold",
        // },
      },
      MuiDialogActions: {
        root: {
          justifyContent: "center",
          marginBottom: "5%",
        },
      },
      MuiDivider: {
        root: {
          height: "40%",
        },
      },
      // MuiCircularProgress: {
      //   root: {
      //     marginLeft: "50vw",
      //     marginTop: "50vh",
      //   },
      // },
    },
  });
};
// export const isMedium = useMediaQuery((theme) => theme.breakpoints.down("md"));
