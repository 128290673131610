import { SET_LOADING, SET_ALERT } from "../actions";

export function startLoading() {
  return {
    type: SET_LOADING,
    payload: { loading: true }
  };
}

export function stopLoading() {
  return {
    type: SET_LOADING,
    payload: { loading: false }
  };
}

export function showAlert(payload) {
  return {
    type: SET_ALERT,
    payload
  };
}

export function removeAlert() {
  return {
    type: SET_ALERT,
    payload: { alertMessage: "", persistAlert: false }
  };
}
