import React, { lazy, Suspense } from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    // height: "calc(100vh - 64px)",
    // height: "100vh",
    // overflowY: "auto",
    overflowX: "hidden",
    backgroundColor: "#F9F9F9",
    [theme.breakpoints.up("md")]: {
      // width: "50%",
      width: "60%",
      margin: "0 auto",
    },
    [theme.breakpoints.up("sm")]: {
      // width: "50%",
      width: "60%",
      margin: "0 auto",
    },
  },
}));

const pages = [
  {
    component: lazy(() => import("./pages/Login")),
    route: "/login",
    id: "login",
  },
  {
    component: lazy(() => import("./components/Login/OtpVerification")),
    route: "/verify",
    id: "verify",
  },
  {
    component: lazy(() => import("./pages/Home")),
    route: "/home",
    id: "home",
  },
  {
    component: lazy(() => import("./pages/View")),
    route: "/view",
    id: "view",
  },
  {
    component: lazy(() => import("./pages/OrderAcknowledgement")),
    route: "/orderacknowledgement",
    id: "orderacknowledgement",
  },
];

function Routes({ ...otherProps }) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            {pages.map((page) => {
              if (page.id === "home") {
                return (
                  <Route
                    // exact
                    key={page.id}
                    path={page.route}
                    render={() => (
                      <page.component
                      // page props can be passed here, if required
                      />
                    )}
                  />
                );
              }
              if (page.id === "verify") {
                return (
                  <Route
                    exact
                    key={page.id}
                    path={page.route}
                    render={() => (
                      <page.component
                      // page props can be passed here, if required
                      />
                    )}
                  />
                );
              }
              if (page.id === "view") {
                return (
                  <Route
                    // exact
                    key={page.id}
                    path={page.route}
                    render={() => (
                      <page.component
                      // page props can be passed here, if required
                      />
                    )}
                  />
                );
              }
              if (page.id === "orderacknowledgement") {
                return (
                  <Route
                    exact
                    key={page.id}
                    path={page.route}
                    render={() => (
                      <page.component
                      // page props can be passed here, if required
                      />
                    )}
                  />
                );
              }

              return (
                <Route
                  // exact
                  key={page.id}
                  path={page.route}
                  component={page.component}
                />
              );
            })}
            <Redirect to="/login" />
          </Switch>
        </Suspense>
      </div>
    </React.Fragment>
  );
}

export default Routes;
