import ViewMenuIcon from "../assets/img/ViewMenuIcon.png";
import OrderAcknowledgementIcon from "../assets/img/OrderAcknowledgementIcon.png";
import FeedbackIcon from "../assets/img/FeedbackIcon.png";
import FoodUptake from "../assets/img/FoodUptake.png";
import MenuChange from "../assets/img/MenuChange.png";
import FoodTruckTracking from "../assets/img/FoodTruckTracking.png";

export const KITCHEN_FOODCOURTS = ["MOEKT"];
export let LOCATION = "nzmoe";

export let BASE_URL = "../";
export const VERSION = "v2/app/";
const hostname = window.location.hostname;
export let DOMAIN_NAME = hostname;

if (process.env.NODE_ENV === "development") {
  // BASE_URL = "https://moe-dot-smartqdemo-nz.appspot.com/";
  BASE_URL = "https://nzdemo.thesmartq.com/";
  DOMAIN_NAME = "nzdemo.thesmartq.com";
}

// if (["localhost", "demo", "nzdemo", "lunches4me.com"].includes(hostname)) {
//   LOCATION = "NZMOE";
// }
if (
  hostname.includes("localhost") ||
  hostname.includes("demo") ||
  hostname.includes("nzdemo") ||
  hostname.includes("lunches4me.com")
) {
  LOCATION = "NZMOE";
}
export const PREFIX = "/v2/app";
export const LOCATION_AUTH = BASE_URL + VERSION + "location/auth";
export const LOGIN = BASE_URL + VERSION + "user/login";
export const VERIFICATION = BASE_URL + VERSION + "user/verify";
export const LOGOUT = BASE_URL + VERSION + "user/logout";
export const MENU = BASE_URL + VERSION + "menu";
export const ORDER_ACKNOWLEDGEMENT =
  BASE_URL + VERSION + "school/order/acknowledge";
export const GENERIC_APP_CONTROLLER =
  BASE_URL + VERSION + "generic_app_controller";
export const RATING = BASE_URL + VERSION + "rating";
export const ITEM_SHARE_BASE_URL = `https://${DOMAIN_NAME}${PREFIX}/foodinfo`;
const CONVERT_STRING_TO_BYTE_ARRAY = (str = "") => {
  var bytesv2 = []; // char codes

  for (var i = 0; i < str.length; ++i) {
    var code = str.charCodeAt(i);
    bytesv2 = bytesv2.concat([code & 0xff, (code / 256) >>> 0]);
  }

  return bytesv2;
};
export const CONVERT_STRING_TO_HEX = (string) => {
  let byteArray = CONVERT_STRING_TO_BYTE_ARRAY(string);

  var s = "";
  let counter = 0;
  byteArray.forEach(function (byte) {
    // a zero is added before every conversion, eg: 4a 0, 4c 0, 4b 0...
    // we aren't interested in that additonal zero, because of which
    // we've written the counter logic

    counter += 1;
    let v = (byte & 0xff).toString(16).slice(-2);
    if (counter % 2 !== 0) {
      s += v;
    }
  });
  return s;
};
export const GET_ITEM_SHARE_URL = (foodid) => {
  return `${ITEM_SHARE_BASE_URL}/${CONVERT_STRING_TO_HEX(foodid)}`;
};

export const ACTIONS = {
  moe_middaymeal: "moe_middaymeal",
  moe_menuchange: "moe_menuchange",
  moe_uptake: "moe_uptake",
  moe_foodtracking: "moe_foodtracking",
};

export const ITEM_VIEW = [
  {
    title: "View Menu",
    description: "Click here to see the menu for next 7 days",
    image: ViewMenuIcon,
    id: "view",
  },
  // {
  //   title: "Order Acknowledgement",
  //   description: "Check your order status and acknowledge when delivered",
  //   image: OrderAcknowledgementIcon,
  //   id: "orderack",
  // },
  {
    title: "Feedback",
    description:
      "We would love to hear from you so that we could improve our services",
    image: FeedbackIcon,
    id: "feedback",
    action: ACTIONS.moe_middaymeal,
  },
  // {
  //   title: "Student Numbers Temporary Changes",
  //   description:
  //     "Request for an order change by choosing the date and place a modified request.",
  //   image: MenuChange,
  //   id: "menuchange",
  //   action: ACTIONS.moe_menuchange,
  // },
  // {
  //   title: "Tracking of Uptake",
  //   description:
  //     "Click here to record the uptake of your class based on consumption.",
  //   image: FoodUptake,
  //   id: "uptaketracking",
  //   action: ACTIONS.moe_uptake,
  // },
  {
    title: "Food Truck Tracking",
    description: "Click here to view the status of your food truck.",
    image: FoodTruckTracking,
    id: "foodtrucktrack",
    action: ACTIONS.moe_foodtracking,
  },
  // {
  //   title: "",
  //   description: "",
  // },
];
